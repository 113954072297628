.roundType {
    max-width: 375px;
    height: 812px;
    background-image: url("../assets/landing.png");
}
.gameRulesRoundType {
    display: inline-block;
    position: absolute;
    margin-top: 145px;
    margin-left: 30px;
    margin-bottom: 20px;
    font-family: Josefin Sans, sans-serif;
    font-weight: bold;
    color: blue;
    text-decoration: none;
}
.oneRoundType {
    padding-top: 52%;
    text-align: center;
}
.twoRoundType {
    text-align: center;
}
.threeRoundType {
    text-align: center;
}
.inputRoundType {
    width: 350px;
    height: 50px;
    border-radius: 25px;
    border-color: black;
    border-width: 3px;
    background-color: white;
    font-size: 20px;
    line-height: 3;
    padding-left: 25px;
    margin-left: 12px;
}
.buttonRoundType {
    width: 275px;
    height: 50px;
    border-width: 3px;
    border-radius: 25px;
    border-color: black;
    font-family: Josefin Sans, sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: black;
    background-color: #f9dd25;
    margin-left: 50px;
}