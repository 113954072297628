.gamerules {
    max-width: 440px;
    height: 812px;
}
.buttonGameRules {
    width: 275px;
    height: 50px;
    border-width: 3px;
    border-radius: 25px;
    border-color: black;
    font-family: Josefin Sans, sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: black;
    background-color: #f9dd25;
    display:block;
    margin-left: auto;
    margin-right: auto;
}
.headerGameRules {
    font-family: "Josefin Sans", sans-serif;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    top: 85px;
    left: 131px;
}
.listGameRules {
    font-family: "Josefin Sans", sans-serif;
    font-weight: lighter;
    font-size: 20px;
    width: 375px;
    height: 812px;
    top: 135px;
    bottom: 161px;
    left: 19px;
    right: 16px;
    position: absolute;
    text-align: left;
    line-height: 24px;
}