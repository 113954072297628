.googlephotos {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bold;
    font-size: 32px;
    background-color: #C8DAD8;
    font-family: "Grandstander";
}
.headerGooglePhotos{
    text-align: center;
}
.containerGooglePhotos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
    place-items: center;
}
.imageGooglePhotos {
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.selectedGooglePhotos {
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 275px;
    height: auto;
    border-width: 3px;
    border-radius: 25px;
    border-color: #DC816A;
    font-family: Grandstander;
    font-weight: bold;
    font-size: 20px;
    color: white;
    background-color: #DC816A;
    white-space: normal;
}
.buttonGooglePhotos {
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 275px;
    height: auto;
    border-width: 3px;
    border-radius: 25px;
    border-color: #46C3A6;
    font-family: Grandstander;
    font-weight: bold;
    font-size: 20px;
    color: white;
    background-color: #46C3A6;
    text-align: center;
    white-space: normal;
}