.CnnDeckContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:rgba(153, 90, 98, 0.7)

}

.CnnCard {
    width: 60%; /* Each card width is 90% of the screen width */
    /*margin: 10px auto;*/ /* Center the card horizontally */
    /* border-top: 1px solid #ccc; */
    border-bottom: 1px solid white;
    /* border-radius: 5px; */
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s;
    display: flex; /* Use flexbox */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space items horizontally */
    padding : 20px;
}

.CnnCard:hover {
    transform: scale(1.05);
    /* background-color: rgba(120, 70, 80, 0.7);  for darker bg */
     background-color: rgba(220, 170, 180, 0.7);  /*for lighter  bg */
}

.CnnCardImage {
    width: 30%; /* Adjust image width */
    height: auto; /* Maintain aspect ratio */
    margin-left: 5px; /* Add some spacing */
}

.CnnCardText {
    width: 65%; /* Adjust text width */
    padding: 10px;
    font-size: 16px;
    text-align: left; /* Align text to the left */
    color: #F8F8FF;
}

.page-heading{
    margin-top: 30px;
    color:  #F8F8FF
}