.endgame {
    max-width: 390px;
    min-height: 844px;
    background-color: red;
}
.headerEndGame, .columnsEndGame {
    text-align: center;
}
.columnsEndGame {
    text-align: center;
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 25px;
    font-family: Josefin Sans, sans-serif;
    font-size: 18px;
}
.valuesEndGame {
    text-align: center;
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 10px;
    font-family: Josefin Sans, sans-serif;
    font-size: 18px;
}
.buttonEndGame {
    display:block;
    margin-left: auto;
    margin-right: auto;
    width: 215px;
    height: 60px;
    border-radius: 30px;
    border-color: yellow;
    border-width: 5px;
    color: black;
    font-family: Josefin Sans, sans-serif;
    font-size: 18px;
}
.loadingimg {
    margin-left: 4rem;
    width: 10rem;
}
.results {
    background-image: none;
    height: auto;
    margin-right: 5rem;
    margin-left: 60px;
}

.score {
    margin-right: 8rem !important;
}

.winCaption {
    background-color: #F5F5F5;
    width: 342px;
    min-height: 60px;
    border-radius: 30px;
    border-color: black;
    border-width: 1px;
    font-size: 20px;
    font-weight: 600;
    font-weight: normal;
    font-family: Grandstander;
    line-height: 3;
    cursor: default !important;
}