.confirmation {
    text-align: center;
    max-width: 700px;
    height: 812px;
}
.buttonConfirmation {
    width: 275px;
    height: 50px;
    border-width: 3px;
    border-radius: 25px;
    border-color: black;
    font-family: Josefin Sans, sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: black;
    background-color: #f9dd25;
}
.validConfirmation {
    color: red;
}