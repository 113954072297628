/* .container-main {
  max-width: 375px;
  height: 812px;
  background-image: url("../assets/landing.png");
  background-repeat: no-repeat;
  margin-left: 0;
} */

.gameRules {
  font-family: Josefin Sans, sans-serif;
  font-weight: bold;
  color: blue;
  text-decoration: none;
}
