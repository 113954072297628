.selectDeck {
    maxWidth: 375px;
    height: 812px;
}
.gameRulesSelectDeck {
    display: inline-block;
    position: absolute;
    margin-top: 52px;
    margin-left: 30px;
    margin-bottom: 20px;
    font-family: Josefin Sans, sans-serif;
    font-weight: bold;
    color: blue;
    text-decoration: none;
}
.oneSelectDeck {
    padding-top: 93px;
    margin-left: 115px;
}
.deck-container {
    max-width: 375px;
    height: 812px;
    margin-top: 100px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    list-style: none;
}
.deck {
    font-weight: bold;
    color: black;
    margin: 10px;
}
.deck-background {
    cursor: pointer;
    height: 160px;
    width: 160px;
    background-color: yellow;
}
.deck-image {
    object-fit: cover;
    height: 110px;
    width: 110px;
    position: relative;
    top: 50%;
    margin-left: 15%;
    transform: translateY(-50%);
    border-radius: 20px;
}
.deckText {
    font-family: Grandstander;
    font-weight: 700;
    font-size: 13px;
    font-style: normal;
    margin-top: -48px;
    margin-left: 20px;
    text-align: center;
    color: white;
}